import React, { Component } from 'react';
class Blue extends Component {
 
   constructor(props) {
    super(props)
    this.path="https://www.sagishutrit.nl/images/disasters-in-blue"
    this.state = {
      paintings:[
    {id:1,src:'https://www.sagishutrit.nl/images/disasters-in-blue/man_drowning.jpeg', func:"dsds", format:"", title:"Man drowning", year:"2008"},
    {id:2, src:"https://www.sagishutrit.nl/images/disasters-in-blue/jongeman_ik_heb_geen_werk_voor_je.jpeg", func:"", format:"", title:"untitled", year:"2007"},
    {id:3,src:'https://www.sagishutrit.nl/images/disasters-in-blue/landscape_of_open_rooms.jpeg', func:"dsds",  format:"",  title:"Landscape of open rooms", year:"2008"},
    {id:4, src:"https://www.sagishutrit.nl/images/disasters-in-blue/bufferzone.jpeg", func:"", format:"", title:"Buffer zone", year:"2008"},
    {id:5, src:'https://www.sagishutrit.nl/images/disasters-in-blue/6jun1207.jpeg', func:"dsds", format:"", title:"Study of Piet", year:"2006"},
    {id:6, src:"https://www.sagishutrit.nl/images/disasters-in-blue/5jun12.jpeg", func:"", format:"", title:"untitled", year:"2008"},
    ],
    current:null,
    view:this.views[0],
    }
   }
   title="Disasters in blue";
   views = ['all','individual'];
    componentDidMount() {
       this.isGrid = true;
       document.getElementById("blue").classList.add("current");
    } 
    handleStep(stateId) {
      const current =  this.state.paintings.filter(x=>x.id === stateId)
      this.setState({view:this.views[1],current:current[0]})

  }
    getTheSelected() {
      return  <div className="merit" style={{display:'flex'}} ref ={this.myRef}>
      <div style={{ margin:'10px',display:'flex',flexDirection:'column'}}>
      <img src={this.state.current.src} style={{maxWidth:'600px'}} alt="yes"/>
          <div style={{ display:'flex',justifyContent:'space-between'}}>
          <span style={{ margin:'10px'}}>{this.state.current.title}</span>
          <span style={{ margin:'10px'}}>{this.state.current.price}</span>
          <span style={{ margin:'10px'}}>{this.state.current.size}</span>
          </div>
      </div>   
</div>
    }
    getAllFour(){
      return this.state.paintings.map(obj=><div className="col-sm-12 col-md-6 tmuna" key={obj.id}  onClick={()=>this.handleStep(obj.id)} style={{display:'flex', flexDirection:'column'}}><img src={obj.src} alt="all rights reserved" style={{maxWidth:'100%'}}/>
      <span>{obj.title}</span></div>)
    }
    render() {
      if(this.state.view === this.views[0])
      return <div clsassName="row">
          {this.getAllFour()}
          </div>
      else {
          //individual
          return this.getTheSelected()
      }
    }

}

export default Blue;