import React from "react";
import { render } from "react-dom";
import {  Routes, Route, BrowserRouter } from 'react-router-dom';
import Header from "./components/Header";
import Blue   from "./components/Blue";
import StripedRock from "./components/StripedRock";
import About from "./components/About";
import Home from "./components/Home";
import AcidBloom from "./components/AcidBloom";
import Contact from "./components/Contact";


const BasicExample = () => {
  
  return ( 
      <div className="row">
      <div className="col-sm-12 col-md-2 mitgar">        
         <Header/>    
      </div>

      <div style={{ border:'0px solid gold',marginTop:'0'}} className="col-sm-12 col-md-10 tmuna">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="about" element={<About />} />
        <Route path="disasters-in-blue" element={< Blue Suker="l"/>} />
        <Route path="striped-rock" element={<StripedRock  />} />
        <Route path="acid-bloom" element={<AcidBloom  />} />
        <Route path="contact" element={< Contact/>} />
      </Routes>
      </BrowserRouter>
      </div>
      </div>
  );
}
render(<BasicExample/>, document.getElementById("root-container"));
