import  { Component } from 'react';

class About extends Component {

    constructor(props){
        super(props)
        this.state = {
            public:['one', 'two'],
            article:"During that time Sagi worked as a painter and performance artist",
            image:"https://www.sagishutrit.nl/images/profile/sagi_langevoorhuit.jpg",
            title:'About'

        }
    }
    componentDidMount() {
        document.getElementById("about").classList.add("current");

        
    }
    onChange() {
        console.log(this.props);
    }
    render(){
        return(<div className='row'>
                <div className='col-sm-12 col-md-6'><img style={{maxWidth:'100%'}} src={this.state.image} alt='artist-profile'/></div>
                <div className='col-sm-12 col-md-6 content-about-width'>
                <p style={{ color: 'rgb(72, 72, 72)', fontSize: '1.2em' }}>Sagi Shutrit (b. 1978, Tel Aviv, IL) studied at the Royal Academy  of Arts in The Hague, Netherlands.</p>
                <p style={{ color: 'rgb(72, 72, 72)', fontSize: '1.2em' }}>After graduation from the KABK Sagi went into full time painting years in the area of The Hague and have had a two solo shows 
                in The Hague and Tel Aviv.</p>
                <p style={{ color: 'rgb(72, 72, 72)', fontSize: '1.2em' }}> The style in his works was compared to verious artists engaged is post apocalytic imagery and fragmentism.</p>
                <p style={{ color: 'rgb(72, 72, 72)', fontSize: '1.2em' }}>During that time Sagi worked as a painter and performance artist
                Sagi currently lives and works in The Hague and Amsterdam.
                </p>
                </div>
        </div>);
    }
    
}
export default About