
import Blue from "./Blue";

class StripedRock extends Blue {
    constructor(props) {
        super(props)
        this.state = {
            paintings:[ 
            {id:1,src:'https://www.sagishutrit.nl/images/striped-rock/stripedrock28.jpg', func:"dsds",  format:"",  title:"afternoon offering", year:"2012"},
            {id:2, src:"https://www.sagishutrit.nl/images/striped-rock/kingkong.gif", func:"", format:"", title:"king kong", year:"2005"},
            {id:3, src:'https://www.sagishutrit.nl/images/striped-rock/StripedRock5.jpg', func:"dsds", format:"", title:"Asylum boat", year:"2006"},
          ],
          current:null,
          view:this.views[0]
        }
    }
    componentDidMount(){
        document.getElementById("striped-rock").classList.add("current");
    }
    title= "Striped Rock";
  
}
export default StripedRock;