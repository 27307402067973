import menu_burger from '../assets/menu_burger.svg'
import { useState, useEffect } from "react";
function burger () {
  const nav =  document.querySelectorAll(".nav")
  if(nav && nav[0]) {   
    if(nav[0].classList.contains('open')) {
      nav[0].classList.remove("open")
    } else {
      nav[0].classList.add("open")
    }
  }
}
const BREAKPOINT = 1015;
function Header() {
  const size = useWindowSize();
  const d =  document.querySelectorAll(".mitgar")

  const headertop = document.getElementById('nomo12');
  if(window.innerWidth < BREAKPOINT ) {
    headertop.style.display = 'none'
    if(d && d[0]) {
      d[0].classList.add("mob-menu")
    } 
  
    if(headertop) {
      headertop.classList.add('mobi')
    }
  
} 
if(window.innerWidth > BREAKPOINT) {
  headertop.style.display = 'block';
   if(d && d[0]){
   if(d[0].classList.contains('mob-menu')) {
        d[0].classList.remove('mob-menu')
        console.log("REMOVE>",size)
       }
     }
}
 
  return (

    <div className="side-nav">
    <h1 style={{marginLeft:'10px',marginTop:'0'}}><a  href="/">SAGI SHUTRIT</a></h1>
    <div className='top'>
      <img onClick={burger} src={menu_burger} alt='menu'/>
      <ul className="nav">
        <li><a id="about" href="about">About</a></li>
        <li><a id="blue" href="disasters-in-blue">Disasters in blue</a></li>
        <li><a id="striped-rock" href="striped-rock">Striped rock</a></li>
        <li><a id="acid-bloom" href="acid-bloom">Acid boom</a></li>
        <li><a id="contact" href="contact">Contact</a></li>
      </ul>
    </div>
    </div>

  );
}
// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup

    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
export default Header